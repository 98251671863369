import React from 'react';
import { Link } from 'gatsby';
import Slugger from 'github-slugger';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';

const slugger = new Slugger();

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Headings = ({ items }) => (
  <ul>
    {items.map((item) => (
      <li key={item.url}>
        <Link to={item.url}>{item.title}</Link>
        {item.items && item.items.length > 0 && (
          <Headings items={item.items} />
        )}
      </li>
    ))}
  </ul>
);

export default ({ toc }) => (
  <Disclosure as="div" className="pt-6">
    {({ open }) => (
      <>
        <Disclosure.Button className="bg-indigo-200 focus:outline-none text-left w-full flex justify-between items-center text-gray-400 p-3 mb-7">
          <h2 style={{ marginTop: 0, marginBottom: 0 }}>Table of contents</h2>
          <span className="ml-6 h-7 flex items-center">
            <ChevronDownIcon
              className={classNames(
                open ? '-rotate-180' : 'rotate-0',
                'h-6 w-6 transform text-indigo-500'
              )}
              aria-hidden="true"
            />
          </span>
        </Disclosure.Button>
        <Disclosure.Panel>
          <Headings items={toc.items}/>
          {/* <ul>
            {headings.items
              // .filter((heading) => heading.depth !== 1)
              .map((heading) => (
                <li key={heading.url} className="text-sm">
                  <Link to={heading.url}>{heading.title}</Link>
                  {heading.items && heading.items.length > 0 && (
                    <ul>
                      {heading.items.map((item) => (
                        <li key={item.url}>
                          <Link to={item.url}>{item.title}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
          </ul> */}
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
);
