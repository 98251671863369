import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import SEO from 'react-seo-component';
// import { Layout } from '../components/Layout';
import { useSiteMetadata } from '../hooks/useSiteMetadata';
import CTAs from '../components/CTAs';
import TOC from '../components/TOC';

const BlogsTemplate = ({ data, pageContext }) => {
  const {
    title: siteTitle,
    image,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
    authorName,
  } = useSiteMetadata();
  const {
    frontmatter,
    body,
    fields,
    excerpt,
    headings,
    timeToRead,
    tableOfContents,
  } = data.mdx;
  const { title, description, date, cover, categories, card } = frontmatter;
  // const { previous, next } = pageContext;
  return (
    <>
      <Layout>
        <SEO
          title={title}
          titleSeparator={`|`}
          titleTemplate={siteTitle}
          description={description || excerpt}
          pathname={`${siteUrl}/${fields.slug}`}
          siteLanguage={siteLanguage}
          siteLocale={siteLocale}
          twitterUsername={twitterUsername}
          author={authorName}
          article={true}
          publishedDate={date}
          image={`${siteUrl}${card.publicURL}`}
        />
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <h1>{frontmatter.title}</h1>
            <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4">
              {categories &&
                categories
                  .split(' ')
                  .map((category) => (
                    <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm uppercase font-medium bg-indigo-100 text-indigo-800">
                      {category}
                    </span>
                  ))}
            </div>
            {/* <p>{frontmatter.date}</p>
          <p className="text-red-500">{excerpt}</p> */}
            <div className="mt-6 flex items-center">
              <div className="flex-shrink-0">
                <span className="sr-only">{authorName}</span>
                {/* <img
                className="h-10 w-10 rounded-full"
                src={'post.author.imageUrl'}
                alt=""
              /> */}
                {/* <StaticImage
                className="h-10 w-10"
                width={50}
                height={50}
                src="../images/benefit-friendly-technical-support.png"
                alt="Radi Danchev"
              /> */}
              </div>
              <div className="">
                <div className="text-sm font-medium text-gray-500">
                  {authorName}
                </div>
                <div className="flex space-x-1 text-sm text-gray-400">
                  {/* <time dateTime={post.datetime}>{post.date}</time> */}
                  {/* <span aria-hidden="true">&middot;</span> */}
                  <span>{timeToRead} mins read</span>
                </div>
              </div>
            </div>

            <MDXProvider components={{ TOC }}>
              <MDXRenderer toc={tableOfContents}>{body}</MDXRenderer>
            </MDXProvider>
          </div>
        </div>
        {/* <CTAs.SimpleJustified
          subtitle="Start using Easy Tag today."
          primaryActionLabel="Get Started"
        /> */}
        <CTAs.BigEasyTag />
      </Layout>
    </>
  );
};

export default BlogsTemplate;

export const query = graphql`
  query PostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        date(formatString: "YYYY MMMM Do")
        # cover {
        #   publicURL
        # }
        card {
          publicURL
        }
        categories
      }
      body
      excerpt
      fields {
        slug
      }
      headings {
        depth
        value
      }
      tableOfContents
      timeToRead
    }
  }
`;
